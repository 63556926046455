import {FC} from 'react'
import {Provider} from 'react-redux'
import './App.css'
import {initStore} from './state/store'
import {Router} from './Router'
import {persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'

const store = initStore()
const persistor = persistStore(store)

const App: FC = () => {
    return (
        <Provider store={store}>
            <PersistGate
                loading={null}
                persistor={persistor}>
                <Router />
            </PersistGate>
        </Provider>
    )
}

export default App
