/* eslint-disable @typescript-eslint/no-explicit-any */
import {Binding, EndpointParams} from 'react-api-data'

type WithRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>

type BindingSuccess<T, S, Params extends EndpointParams, Body_1 = any> = Omit<
    WithRequired<Binding<T, S, Params, Body_1>, 'data'>,
    'dataFailed'
>

type BindingFailed<T, S, Params extends EndpointParams, Body_1 = any> = Omit<
    WithRequired<Binding<T, S, Params, Body_1>, 'dataFailed'>,
    'data'
>

export const isSuccess = <T, S, Params extends EndpointParams, Body_1 = any>(
    binding: Binding<T, S, Params, Body_1>,
): binding is BindingSuccess<T, S, Params, Body_1> => {
    return binding.request.networkStatus === 'success'
}

export const isFailed = <T, S, Params extends EndpointParams, Body_1 = any>(
    binding: Binding<T, S, Params, Body_1>,
): binding is BindingFailed<T, S, Params, Body_1> => {
    return binding.request.networkStatus === 'failed'
}
