import {FC, useCallback} from 'react'
import './Sidebar.css'
import {Link, useLocation, useParams} from 'react-router-dom'
import classnames from 'classnames'
import {useSelector} from '../hooks/useSelector'
import {isLoggedIn} from '../state/user'
import {usePostLogout} from '../generated/apiData'

type Params = {
    idTeams?: string
}

type MenuItem = {
    name: string
    url: string
    current?: boolean
}

const menu: MenuItem[] = [
    {
        name: 'Lists',
        url: '/lists/',
    },
]

const addParamsToUrl = (
    url: string,
    params: Record<string, string | number>,
): string =>
    Object.keys(params)
        .reverse()
        .reduce(
            (acc, key) => acc.replaceAll(`:${key}`, params[key].toString()),
            url,
        )

type Props = {
    width: number
    onMenuClick: () => void
}

const Sidebar: FC<Props> = ({width, onMenuClick}) => {
    const idTeams = parseInt(useParams<Params>().idTeams ?? '', 10)
    const params = {
        idTeams,
    }
    const location = useLocation()
    const loggedIn = useSelector(isLoggedIn)
    const postLogout = usePostLogout()
    const logout = useCallback(() => {
        onMenuClick()
        void postLogout.perform()
    }, [])
    return (
        <div
            style={{translate: width - 200}}
            className="left-menu">
            <div className="left-menu">
                <img
                    id="menulogo"
                    src="https://cp.apptosuccess.com/images/menulogo.png"
                />
                <hr />
                <ul className="menu">
                    {loggedIn ? (
                        <>
                            {menu.map(item => {
                                const to = addParamsToUrl(item.url, params)
                                return (
                                    <li key={item.name}>
                                        <Link
                                            to={to}
                                            onClick={onMenuClick}
                                            className={classnames({
                                                currentCat:
                                                    location.pathname.startsWith(
                                                        to,
                                                    ),
                                                currentPage:
                                                    to === location.pathname,
                                                current:
                                                    location.pathname.startsWith(
                                                        to,
                                                    ),
                                            })}>
                                            {item.name}
                                        </Link>
                                    </li>
                                )
                            })}
                            <li>
                                <a
                                    href="#"
                                    onClick={logout}>
                                    Logout
                                </a>
                            </li>
                        </>
                    ) : (
                        <li>
                            <a
                                href="#"
                                onClick={onMenuClick}
                                className="currentCat currentPage current">
                                Login
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default Sidebar
