import {useState, useEffect, useRef} from 'react'

const useAnimationTimer = (duration = 1000): [number, () => void] => {
    const [_time, setTime] = useState(0)
    const startTime = useRef<number>(Date.now())
    const animationFrame = useRef<number>(0)

    const onFrame = () => {
        const value = Date.now() - startTime.current
        if (value > duration) {
            setTime(duration)
        } else {
            setTime(value)
            loop()
        }
    }

    const loop = () => {
        animationFrame.current = requestAnimationFrame(onFrame)
    }

    const start = () => {
        // cleanup old timers
        cancelAnimationFrame(animationFrame.current)

        startTime.current = Date.now()
        loop()
    }

    useEffect(
        () => () => {
            cancelAnimationFrame(animationFrame.current)
        },
        [],
    )
    const elapsed = Math.min(Date.now() - startTime.current, duration)
    return [elapsed, start]
}

export default useAnimationTimer
