import {FC, useState} from 'react'
import {usePostLogin} from '../generated/apiData'
import ScreenWrapper from '../components/ScreenWrapper'
import {isSuccess} from '../utils/api'
import {useDispatch} from '../hooks/useDispatch'
import {UserActionType} from '../state/user'

const LoginScreen: FC = () => {
    const login = usePostLogin()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
        if (username && password) {
            void login
                .perform(
                    {},
                    {
                        Username: username,
                        Password: password,
                    },
                )
                .then(result => {
                    if (isSuccess(result)) {
                        dispatch({
                            type: UserActionType.login,
                            payload: {
                                token: result.data.Token,
                                idUsers: result.data.idUsers,
                            },
                        })
                    }
                })
        }
        event.preventDefault()
    }
    return (
        <ScreenWrapper title="Login">
            <form onSubmit={onSubmit}>
                <label>
                    Username:
                    <br />
                    <input
                        type="text"
                        onChange={({target: {value}}) => setUsername(value)}
                        value={username}
                    />
                    <br />
                    Password:
                    <br />
                    <input
                        type="password"
                        onChange={({target: {value}}) => setPassword(value)}
                        value={password}
                    />
                    <input
                        type="submit"
                        value="Login"
                    />
                </label>
            </form>
        </ScreenWrapper>
    )
}

export default LoginScreen
