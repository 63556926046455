import {FC, useCallback, useState} from 'react'
import {Link} from 'react-router-dom'
import {
    useDeleteListsIdLists,
    useGetItemsperlist,
    usePostItemsperlist,
    usePostLists,
    usePutListsIdLists,
} from '../generated/apiData'
import {Itemsperlist, Lists} from '../generated/types'
import ScreenWrapper from '../components/ScreenWrapper'
import Resources from '../components/Resources'

type Props = {
    item: Lists & Omit<Itemsperlist, keyof Lists>
}

const ListsListItem: FC<Props> = ({item}) => {
    const [edit, setEdit] = useState(false)
    const [name, setName] = useState<string>(item.Name)
    const params = {
        idLists: item.idLists,
    }
    const {perform: performPut} = usePutListsIdLists(params)
    const {perform: performDelete} = useDeleteListsIdLists(params)
    const onClick = useCallback(() => setEdit(true), [])
    const onSave = () => {
        void performPut(
            {},
            {
                ...item,
                Name: name,
            },
        )
    }
    const onSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'NumpadEnter' || event.code === 'Enter') {
            onSave()
        }
    }
    return (
        <>
            <td onClick={onClick}>
                {edit ? (
                    <>
                        <input
                            onChange={e => setName(e.target.value)}
                            onKeyUp={onSubmit}
                            value={name}
                        />
                        <button onClick={onSave}>Save</button>
                    </>
                ) : (
                    <Link to={`/lists/${item.idLists}/`}>
                        {item.Name ?? ''}
                    </Link>
                )}
            </td>
            <td>{item.NumberOfItems ?? ''}</td>
            <td>{item.Resetable ? 'Yes' : 'No'}</td>
            <td>{item.Clearable ? 'Yes' : 'No'}</td>
            <td>
                {item.NumberOfItems === 0 ? (
                    <button
                        onClick={() => {
                            if (
                                window.confirm(
                                    `Do you really want to delete list '${item.Name}'?`,
                                )
                            ) {
                                void performDelete()
                            }
                        }}>
                        Delete
                    </button>
                ) : (
                    <i>You can only delete empty lists</i>
                )}
            </td>
        </>
    )
}

const ListsScreen: FC = () => {
    return (
        <ScreenWrapper title="Lists">
            <Resources
                RenderListItem={ListsListItem}
                keyExtractor={item => item.idLists.toString()}
                useGetEndpoint={useGetItemsperlist}
                usePostEndpoint={usePostLists as typeof usePostItemsperlist}
                params={{}}
                resourceName="list"
                ItemContainerElement="tr"
                ItemsContainer={({children}) => (
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Items</th>
                                <th>Resetable</th>
                                <th>Clearable</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        {children}
                    </table>
                )}
            />
        </ScreenWrapper>
    )
}

export default ListsScreen
