/* eslint-disable sonarjs/no-duplicate-string */
import {EndpointConfig, GlobalConfig, Method} from 'react-api-data'
import {State} from './state/state'
import {UserActionType, isLoggedIn} from './state/user'

const getUrl = (path: string) => path

export const globalConfig: GlobalConfig = {
    setHeaders: (_defaultHeaders: Record<string, string>, state: State) => {
        const authorizationHeaders: {Authorization?: string} = {}
        if (isLoggedIn(state)) {
            authorizationHeaders.Authorization = `Bearer ${
                state.user.token ?? ''
            }`
        }
        return {
            ..._defaultHeaders,
            ...authorizationHeaders,
        }
    },
}

export const endpointConfig: Record<string, EndpointConfig> = {
    getItemsperlist: {
        url: getUrl('/api/v1/itemsperlist'),
        method: 'GET' as Method,
    },
    postItemsperlist: {
        url: getUrl('/api/v1/itemsperlist'),
        method: 'POST' as Method,
        afterSuccess: ({actions, request: {params}}) => {
            const {idUsers} = params as {
                idUsers: number
            }
            actions.invalidateCache('getLists', {idUsers})
            actions.invalidateCache('getItemsperlist', {idUsers})
        },
    },
    getListitems: {
        url: getUrl('/api/v1/listitems'),
        method: 'GET' as Method,
    },
    postListitems: {
        url: getUrl('/api/v1/listitems'),
        method: 'POST' as Method,
        afterSuccess: ({actions, request: {params}}) => {
            const {idLists} = params as {idLists: number}
            actions.invalidateCache('getListitems', {
                idLists,
            })
        },
    },
    getListitemsIdListItems: {
        url: getUrl('/api/v1/listitems/:idListItems'),
        method: 'GET' as Method,
    },
    putListitemsIdListItems: {
        url: getUrl('/api/v1/listitems/:idListItems'),
        method: 'PUT' as Method,
        afterSuccess: ({actions, request: {params}}) => {
            const {idLists} = params as {idLists: number}
            actions.invalidateCache('getListitems', {
                idLists,
            })
        },
    },
    deleteListitemsIdListItems: {
        url: getUrl('/api/v1/listitems/:idListItems'),
        method: 'DELETE' as Method,
        afterSuccess: ({actions, request: {params}}) => {
            const {idLists} = params as {idLists: number}
            actions.invalidateCache('getListitems', {
                idLists,
            })
        },
    },
    getLists: {
        url: getUrl('/api/v1/lists'),
        method: 'GET' as Method,
    },
    postLists: {
        url: getUrl('/api/v1/lists'),
        method: 'POST' as Method,
        afterSuccess: ({actions}) => {
            actions.invalidateCache('getLists')
            actions.invalidateCache('getItemsperlist')
        },
    },
    getListsIdLists: {
        url: getUrl('/api/v1/lists/:idLists'),
        method: 'GET' as Method,
    },
    putListsIdLists: {
        url: getUrl('/api/v1/lists/:idLists'),
        method: 'PUT' as Method,
        afterSuccess: ({actions, request: {params}}) => {
            const {idLists} = params as {
                idLists: number
            }
            actions.invalidateCache('getLists')
            actions.invalidateCache('getItemsperlist')
            actions.invalidateCache('getListsIdLists', {
                idLists,
            })
        },
    },
    deleteListsIdLists: {
        url: getUrl('/api/v1/lists/:idLists'),
        method: 'DELETE' as Method,
        afterSuccess: ({actions, request: {params}}) => {
            const {idLists} = params as {
                idLists: number
            }
            actions.invalidateCache('getLists')
            actions.invalidateCache('getItemsperlist')
            actions.invalidateCache('getListsIdLists', {
                idLists,
            })
        },
    },
    postClearlist: {
        url: getUrl('/api/v1/clearlist'),
        method: 'POST' as Method,
        afterSuccess: ({actions, requestBody}) => {
            const {idLists} = requestBody as {idLists: number}
            actions.invalidateCache('getListitems', {
                idLists,
            })
        },
    },
    postLogin: {
        url: getUrl('/api/v1/login'),
        method: 'POST' as Method,
    },
    postLogout: {
        url: getUrl('/api/v1/logout'),
        method: 'POST' as Method,
        afterSuccess: ({dispatch}) => {
            dispatch({type: UserActionType.logout})
            dispatch({type: 'PURGE_ALL_API_DATA'})
        },
    },
    postResetlist: {
        url: getUrl('/api/v1/resetlist'),
        method: 'POST' as Method,
        afterSuccess: ({actions, requestBody}) => {
            const {idLists} = requestBody as {idLists: number}
            actions.invalidateCache('getListitems', {
                idLists,
            })
        },
    },
}
