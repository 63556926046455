import {FC, useCallback, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {
    useDeleteListitemsIdListItems,
    useGetListitems,
    useGetListsIdLists,
    usePostClearlist,
    usePostResetlist,
    usePutListitemsIdListItems,
} from '../generated/apiData'
import ScreenWrapper from '../components/ScreenWrapper'
import Resources from '../components/Resources'
import {Listitems} from '../generated/types'
import AddListItem from '../components/AddListItem'
import {isSuccess} from '../utils/api'

type Params = {
    idLists: string
}

type ListProps = {
    item: Listitems
}

const ListListItem: FC<ListProps> = ({item}) => {
    const [edit, setEdit] = useState(false)
    const [name, setName] = useState<string>(item.Name)
    const [quantity, setQuantity] = useState<number>(item.Quantity ?? 1)
    const [quantityUnit, setQuantityUnit] = useState<string>(
        item.QuantityUnit ?? '',
    )
    const params = {
        idListItems: item.idListItems,
        idLists: item.idLists,
    }
    const {perform: performPut} = usePutListitemsIdListItems(params)
    const {perform: performDelete} = useDeleteListitemsIdListItems(params)
    const onClick = useCallback(() => setEdit(true), [])
    const onSave = () => {
        void performPut(
            {},
            {
                ...item,
                Name: name,
                Quantity: quantity,
                QuantityUnit: quantityUnit,
            },
        )
    }
    const onSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'NumpadEnter' || event.code === 'Enter') {
            onSave()
        }
    }
    if (edit) {
        return (
            <>
                <td>
                    <input
                        onChange={e => setName(e.target.value)}
                        onKeyUp={onSubmit}
                        value={name}
                    />
                    <button onClick={onSave}>Save</button>
                </td>
                <td>
                    <input
                        onChange={e => setQuantity(+e.target.value)}
                        onKeyUp={onSubmit}
                        type="number"
                        value={quantity}
                    />
                    <button onClick={onSave}>Save</button>
                </td>
                <td>
                    <input
                        onChange={e => setQuantityUnit(e.target.value)}
                        onKeyUp={onSubmit}
                        value={quantityUnit}
                    />
                    <button onClick={onSave}>Save</button>
                </td>
                <td>{item.Completed ? 'Yes' : 'No'}</td>
                <td>
                    <button onClick={() => performDelete()}>Delete</button>
                </td>
            </>
        )
    }
    return (
        <>
            <td onClick={onClick}>{name ?? ''}</td>
            <td onClick={onClick}>{quantity ?? ''}</td>
            <td onClick={onClick}>{quantityUnit ?? ''}</td>
            <td onClick={onClick}>{item.Completed ? 'Yes' : 'No'}</td>
            <td>
                <button onClick={() => performDelete()}>Delete</button>
            </td>
        </>
    )
}

const ListScreen: FC = () => {
    const idLists = parseInt(useParams<Params>().idLists ?? '', 10)
    const getLists = useGetListsIdLists({
        idLists,
    })
    const resetItems = usePostResetlist()
    const clearItems = usePostClearlist()
    return (
        <ScreenWrapper
            title={getLists.data?.Name ?? 'Project'}
            backURL={`/lists`}>
            <Resources
                RenderListItem={ListListItem}
                keyExtractor={item => item.idListItems?.toString(10) ?? ''}
                useGetEndpoint={useGetListitems}
                params={{idLists}}
                resourceName="item"
                ItemContainerElement="tr"
                ItemsContainer={({children}) => (
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Quantity</th>
                                <th>Unit</th>
                                <th>Completed</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        {children}
                    </table>
                )}
            />
            <AddListItem idLists={idLists} />
            <hr />
            {isSuccess(getLists) &&
                (getLists.data.Resetable || getLists.data.Clearable) && (
                    <>
                        <h2>Actions</h2>
                        <div style={{gap: 20, display: 'flex'}}>
                            {getLists.data.Resetable && (
                                <button
                                    onClick={() => {
                                        if (
                                            window.confirm(
                                                `Do you really want to mark all items in list '${getLists.data.Name}' uncompleted?`,
                                            )
                                        ) {
                                            void resetItems.perform(
                                                {},
                                                {idLists},
                                            )
                                        }
                                    }}
                                    className="button">
                                    Mark all items uncompleted
                                </button>
                            )}
                            {getLists.data.Clearable && (
                                <button
                                    onClick={() => {
                                        if (
                                            window.confirm(
                                                `Do you really want to delete the items in list '${getLists.data.Name}'?`,
                                            )
                                        ) {
                                            void clearItems.perform(
                                                {},
                                                {idLists},
                                            )
                                        }
                                    }}
                                    className="button">
                                    Delete all items
                                </button>
                            )}
                        </div>
                        <br />
                    </>
                )}
            <h2>Manage list</h2>
            <Link to={`/lists/${idLists}/settings/`}>Adjust settings</Link>
        </ScreenWrapper>
    )
}

export default ListScreen
