import {FC} from 'react'
import './Header.css'

type Props = {
    title: string
    backURL?: string
    toggleMenu: () => void
    width: number
    left: number
}

const Header: FC<Props> = ({title, toggleMenu, width, left}) => (
    <div
        className="header"
        style={{
            width,
            marginLeft: left,
        }}>
        <div
            className="menubutton"
            onClick={toggleMenu}>
            <a
                className="nolink"
                href="#left-menu"></a>
        </div>
        <div className="headerright">{/* <TeamSelector /> */}</div>
        <div className="headercontent">
            <h1>{title}</h1>
        </div>
    </div>
)
export default Header
