import {
    Action,
    applyMiddleware,
    combineReducers,
    legacy_createStore as createStore,
    Store,
} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import {configure, reducer} from 'react-api-data'
import {useMemo} from 'react'
import {globalConfig, endpointConfig} from '../endpointConfig'
import {State} from './state'
import {userReducer} from './user'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

let store: Store<State, Action>

export const initStore = () => {
    store = createStore(
        persistReducer(
            {
                key: 'storage',
                storage,
                whitelist: ['user'],
            },
            combineReducers({apiData: reducer, user: userReducer}),
        ),
        {},
        composeWithDevTools(applyMiddleware(thunkMiddleware)),
    )
    store.dispatch(configure(globalConfig, endpointConfig))
    return store
}

export const useStore = () => {
    return useMemo(() => store ?? initStore(), [])
}
