const regex = /((?<quantity>\d+) ?(?<quantityUnit>\w*) )?(?<name>.+)/

const parseItem = (input: string) => {
    if (input) {
        const results = regex.exec(input)
        const {
            name = '',
            quantity = 1,
            quantityUnit = '',
        } = results?.groups as {
            name: string
            quantity?: string
            quantityUnit?: string
        }
        return {
            name,
            quantity: +quantity,
            quantityUnit,
        }
    }
    return {
        name: '',
        quantity: 1,
        quantityUnit: '',
    }
}

export default parseItem
