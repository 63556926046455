/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Checklists test API
 * OpenAPI spec version: 1.0.0
 */
import {
  useApiData
} from 'react-api-data'
import type {
  HookOptions,
  Method
} from 'react-api-data'
import type {
  Itemsperlist,
  PostItemsperlist,
  Listitems,
  PostListitems,
  Delete,
  Lists,
  PostLists,
  Users,
  PostUsers,
  ProcedureOutClearlist,
  ProcedureInClearlist,
  ProcedureOutLogin,
  ProcedureInLogin,
  ProcedureOutLogout,
  ProcedureInLogout,
  ProcedureOutResetlist,
  ProcedureInResetlist
} from './types'


/**
 * Itemsperlist
 * @summary Get all items in Itemsperlist
 */
export const useGetItemsperlist = (
    params: {}, options?: HookOptions 
) => useApiData<Itemsperlist[], void, {}, unknown>('getItemsperlist', params, options);

/**
 * Itemsperlist
 * @summary Add item to Itemsperlist
 */
export const usePostItemsperlist = (
    params: {} = {}, options?: HookOptions 
) => useApiData<Itemsperlist, void, {}, PostItemsperlist>('postItemsperlist', params, options);

/**
 * Listitems
 * @summary Get all items in Listitems
 */
export const useGetListitems = (
    params: {}, options?: HookOptions 
) => useApiData<Listitems[], void, {}, unknown>('getListitems', params, options);

/**
 * Listitems
 * @summary Add item to Listitems
 */
export const usePostListitems = (
    params: {} = {}, options?: HookOptions 
) => useApiData<Listitems, void, {}, PostListitems>('postListitems', params, options);

/**
 * Listitems
 * @summary Get one items in Listitems
 */
export const useGetListitemsIdListItems = (
    params: {idListItems: number}, options?: HookOptions 
) => useApiData<Listitems, void, {idListItems: number}, unknown>('getListitemsIdListItems', params, options);

/**
 * Listitems
 * @summary Update one item in Listitems
 */
export const usePutListitemsIdListItems = (
    params: Partial<{idListItems: number}> = {}, options?: HookOptions 
) => useApiData<Listitems, void, Partial<{idListItems: number}>, PostListitems>('putListitemsIdListItems', params, options);

/**
 * Listitems
 * @summary Delete one item from Listitems
 */
export const useDeleteListitemsIdListItems = (
    params: Partial<{idListItems: number}> = {}, options?: HookOptions 
) => useApiData<Delete, void, Partial<{idListItems: number}>, unknown>('deleteListitemsIdListItems', params, options);

/**
 * Lists
 * @summary Get all items in Lists
 */
export const useGetLists = (
    params: {}, options?: HookOptions 
) => useApiData<Lists[], void, {}, unknown>('getLists', params, options);

/**
 * Lists
 * @summary Add item to Lists
 */
export const usePostLists = (
    params: {} = {}, options?: HookOptions 
) => useApiData<Lists, void, {}, PostLists>('postLists', params, options);

/**
 * Lists
 * @summary Get one items in Lists
 */
export const useGetListsIdLists = (
    params: {idLists: number}, options?: HookOptions 
) => useApiData<Lists, void, {idLists: number}, unknown>('getListsIdLists', params, options);

/**
 * Lists
 * @summary Update one item in Lists
 */
export const usePutListsIdLists = (
    params: Partial<{idLists: number}> = {}, options?: HookOptions 
) => useApiData<Lists, void, Partial<{idLists: number}>, PostLists>('putListsIdLists', params, options);

/**
 * Lists
 * @summary Delete one item from Lists
 */
export const useDeleteListsIdLists = (
    params: Partial<{idLists: number}> = {}, options?: HookOptions 
) => useApiData<Delete, void, Partial<{idLists: number}>, unknown>('deleteListsIdLists', params, options);

/**
 * Users
 * @summary Get all items in Users
 */
export const useGetUsers = (
    params: {}, options?: HookOptions 
) => useApiData<Users[], void, {}, unknown>('getUsers', params, options);

/**
 * Users
 * @summary Add item to Users
 */
export const usePostUsers = (
    params: {} = {}, options?: HookOptions 
) => useApiData<Users, void, {}, PostUsers>('postUsers', params, options);

/**
 * Users
 * @summary Get one items in Users
 */
export const useGetUsersIdUsers = (
    params: {idUsers: number}, options?: HookOptions 
) => useApiData<Users, void, {idUsers: number}, unknown>('getUsersIdUsers', params, options);

/**
 * Users
 * @summary Update one item in Users
 */
export const usePutUsersIdUsers = (
    params: Partial<{idUsers: number}> = {}, options?: HookOptions 
) => useApiData<Users, void, Partial<{idUsers: number}>, PostUsers>('putUsersIdUsers', params, options);

/**
 * Users
 * @summary Delete one item from Users
 */
export const useDeleteUsersIdUsers = (
    params: Partial<{idUsers: number}> = {}, options?: HookOptions 
) => useApiData<Delete, void, Partial<{idUsers: number}>, unknown>('deleteUsersIdUsers', params, options);

/**
 * Clearlist
 * @summary Execute procedure Clearlist
 */
export const usePostClearlist = (
    params: {} = {}, options?: HookOptions 
) => useApiData<ProcedureOutClearlist, void, {}, ProcedureInClearlist>('postClearlist', params, options);

/**
 * Login
 * @summary Execute procedure Login
 */
export const usePostLogin = (
    params: {} = {}, options?: HookOptions 
) => useApiData<ProcedureOutLogin, void, {}, ProcedureInLogin>('postLogin', params, options);

/**
 * Logout
 * @summary Execute procedure Logout
 */
export const usePostLogout = (
    params: {} = {}, options?: HookOptions 
) => useApiData<ProcedureOutLogout, void, {}, ProcedureInLogout>('postLogout', params, options);

/**
 * Resetlist
 * @summary Execute procedure Resetlist
 */
export const usePostResetlist = (
    params: {} = {}, options?: HookOptions 
) => useApiData<ProcedureOutResetlist, void, {}, ProcedureInResetlist>('postResetlist', params, options);

const getUrl = (path: string) => `${path}`;
export const endpointConfig = {
    getItemsperlist: {
        url: getUrl('/api/v1/itemsperlist'),
        method: 'GET' as Method,
    },
    postItemsperlist: {
        url: getUrl('/api/v1/itemsperlist'),
        method: 'POST' as Method,
    },
    getListitems: {
        url: getUrl('/api/v1/listitems'),
        method: 'GET' as Method,
    },
    postListitems: {
        url: getUrl('/api/v1/listitems'),
        method: 'POST' as Method,
    },
    getListitemsIdListItems: {
        url: getUrl('/api/v1/listitems/:idListItems'),
        method: 'GET' as Method,
    },
    putListitemsIdListItems: {
        url: getUrl('/api/v1/listitems/:idListItems'),
        method: 'PUT' as Method,
    },
    deleteListitemsIdListItems: {
        url: getUrl('/api/v1/listitems/:idListItems'),
        method: 'DELETE' as Method,
    },
    getLists: {
        url: getUrl('/api/v1/lists'),
        method: 'GET' as Method,
    },
    postLists: {
        url: getUrl('/api/v1/lists'),
        method: 'POST' as Method,
    },
    getListsIdLists: {
        url: getUrl('/api/v1/lists/:idLists'),
        method: 'GET' as Method,
    },
    putListsIdLists: {
        url: getUrl('/api/v1/lists/:idLists'),
        method: 'PUT' as Method,
    },
    deleteListsIdLists: {
        url: getUrl('/api/v1/lists/:idLists'),
        method: 'DELETE' as Method,
    },
    getUsers: {
        url: getUrl('/api/v1/users'),
        method: 'GET' as Method,
    },
    postUsers: {
        url: getUrl('/api/v1/users'),
        method: 'POST' as Method,
    },
    getUsersIdUsers: {
        url: getUrl('/api/v1/users/:idUsers'),
        method: 'GET' as Method,
    },
    putUsersIdUsers: {
        url: getUrl('/api/v1/users/:idUsers'),
        method: 'PUT' as Method,
    },
    deleteUsersIdUsers: {
        url: getUrl('/api/v1/users/:idUsers'),
        method: 'DELETE' as Method,
    },
    postClearlist: {
        url: getUrl('/api/v1/clearlist'),
        method: 'POST' as Method,
    },
    postLogin: {
        url: getUrl('/api/v1/login'),
        method: 'POST' as Method,
    },
    postLogout: {
        url: getUrl('/api/v1/logout'),
        method: 'POST' as Method,
    },
    postResetlist: {
        url: getUrl('/api/v1/resetlist'),
        method: 'POST' as Method,
    },
};
