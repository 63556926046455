import {useRef} from 'react'
import useAnimationTimer from './useAnimationTimer'

export type Easing = 'linear' | 'elastic' | 'inExpo' | 'inOutSine'

const useAnimation = ({
    easingName = 'inOutSine',
    duration = 500,
    toValue,
}: {
    easingName?: Easing
    duration?: number
    toValue: number
}) => {
    // const [[startValue, targetValue], setValues] = useState([toValue, toValue])
    const values = useRef([toValue, toValue])
    const [startValue, targetValue] = values.current
    // The useAnimationTimer hook calls useState every animation frame ...
    // ... giving us elapsed time and causing a rerender as frequently ...
    // ... as possible for a smooth animation.
    const [elapsed, start] = useAnimationTimer(duration)

    const n = Math.min(1, elapsed / duration)
    const currentValue =
        easing[easingName](n) * (targetValue - startValue) + startValue
    if (toValue !== targetValue) {
        // start animatie van huidige value naar de toValue met de ingestelde duration
        values.current = [currentValue, toValue]
        start()
    }
    // Amount of specified duration elapsed on a scale from 0 - 1
    // Return altered value based on our specified easing function
    return currentValue
}

// Some easing functions copied from:
// https://github.com/streamich/ts-easing/blob/master/src/index.ts
// Hardcode here or pull in a dependency
const easing: Record<Easing, (n: number) => number> = {
    linear: (n: number) => n,
    elastic: n =>
        n * (33 * n * n * n * n - 106 * n * n * n + 126 * n * n - 67 * n + 15),
    inExpo: n => Math.pow(2, 10 * (n - 1)),
    // Accelerating until halfway, then decelerating
    inOutSine: t => -(Math.cos(Math.PI * t) - 1) / 2,
}

export default useAnimation
