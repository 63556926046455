import {FC, ReactNode, useEffect, useState} from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import useAnimation from '../hooks/useAnimation'

type Props = {
    backURL?: string
    title: string
    children: ReactNode
}

const getIsMobile = () => window.innerWidth < 700

const ScreenWrapper: FC<Props> = ({backURL, title, children}) => {
    const isMobile = getIsMobile()
    const [isSideBaseOpen, setIsSideBaseOpen] = useState(!isMobile)
    const [_windowsWidth, setWindowWidth] = useState(window.innerWidth)
    const left = useAnimation({
        toValue: isSideBaseOpen ? 200 : 0,
        duration: 200,
    })
    const opacity = useAnimation({
        toValue: isSideBaseOpen && isMobile ? 0.5 : 0,
        duration: 200,
    })
    const contentWidth = isMobile ? window.innerWidth : window.innerWidth - left
    useEffect(() => {
        const callback = () => {
            setWindowWidth(window.innerWidth)
            const newIsMobile = getIsMobile()
            if (isMobile !== newIsMobile) {
                setIsSideBaseOpen(!newIsMobile)
            }
        }
        addEventListener('resize', callback)
        return () => removeEventListener('resize', callback)
    }, [isMobile])
    const toggleMenu = () => setIsSideBaseOpen(state => !state)
    return (
        <div
            style={{overflowX: 'hidden'}}
            className={isMobile ? 'mobile' : ''}>
            {/* {isSideBaseOpen && <Sidebar />} */}

            <Sidebar
                width={left}
                onMenuClick={() => {
                    if (isMobile) {
                        setIsSideBaseOpen(false)
                    }
                }}
            />
            <Header
                title={title}
                backURL={backURL}
                toggleMenu={toggleMenu}
                width={contentWidth}
                left={left}
                // isMobile
            />
            <div
                className="hidemenu"
                style={{
                    display: isMobile && isSideBaseOpen ? 'block' : 'none',
                    opacity,
                    left,
                }}
                onClick={toggleMenu}>
                {' '}
            </div>
            <div
                style={{
                    width: contentWidth,
                    marginLeft: left,
                    padding: 10,
                    boxSizing: 'border-box',
                    marginTop: 52,
                }}>
                {/* {backURL && <Link to={backURL}>Back</Link>} */}
                {children}
            </div>
        </div>
    )
}
export default ScreenWrapper
