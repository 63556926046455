import {FC, useEffect, useState} from 'react'
import {useGetListitems, usePostListitems} from '../generated/apiData'
import {isSuccess} from '../utils/api'
import parseItem from '../utils/parseItem'

type AddListItemProps = {
    idLists: number
}

const AddListItem: FC<AddListItemProps> = ({idLists}) => {
    const getResource = useGetListitems({
        idLists,
    })
    const postResource = usePostListitems({idLists})

    const [item, setItem] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [quantity, setQuantity] = useState<number>(1)
    const [quantityUnit, setQuantityUnit] = useState<string>('')

    useEffect(() => {
        const {
            name: parsedName,
            quantity: parsedQuantity,
            quantityUnit: parsedQuantityUnit,
        } = parseItem(item)
        setName(parsedName)
        setQuantity(parsedQuantity)
        setQuantityUnit(parsedQuantityUnit)
    }, [item])

    const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
        if (name) {
            void postResource
                ?.perform(
                    {},
                    {
                        idLists,
                        Name: name,
                        Quantity: quantity,
                        QuantityUnit: quantityUnit,
                    },
                )
                .then(item => {
                    if (isSuccess(item)) {
                        setItem('')
                        setName('')
                        setQuantity(1)
                        setQuantityUnit('')
                        getResource.invalidateCache()
                    }
                })
        }
        event.preventDefault()
    }
    return (
        <form onSubmit={onSubmit}>
            <label>
                Add item:
                <br />
                <input
                    type="text"
                    onChange={({target: {value}}) => setItem(value)}
                    value={item}
                />
            </label>
            <input
                type="submit"
                value="Add"
            />
        </form>
    )
}

export default AddListItem
