import {RequirePropObject} from '../types/RequirePropObject'

export type UserState = {
    token: string | undefined
    idUsers: number | undefined
}

export const defaultState = {
    token: undefined,
    idUsers: undefined,
}

export enum UserActionType {
    login = 'USER_LOGIN',
    logout = 'USER_LOGOUT',
}

export interface LoginAction {
    type: UserActionType.login
    payload: {
        token: string
        idUsers: number
    }
}

export interface LogoutAction {
    type: UserActionType.logout
}

export type UserAction = LoginAction | LogoutAction

export const userReducer = (
    state: UserState = defaultState,
    action: UserAction,
): UserState => {
    switch (action.type) {
        case UserActionType.login:
            return {
                ...state,
                ...action.payload,
            }
        case UserActionType.logout: {
            return defaultState
        }
        default:
            return state
    }
}

export const isLoggedIn = (state: {
    user: UserState
}): state is RequirePropObject<{user: UserState}, 'user'> =>
    state.user.token !== undefined && state.user.idUsers !== undefined
