/* eslint-disable @typescript-eslint/no-explicit-any */
import {FC, ReactNode, useState} from 'react'
import {Binding, HookOptions} from 'react-api-data'
import {isFailed, isSuccess} from '../utils/api'

type Props<
    Result,
    PageParams extends Record<string, any> = Record<string, unknown>,
> = {
    RenderListItem: FC<{item: Result; pageParams: PageParams}>
    RenderEmptyList?: FC<{pageParams: PageParams}>
    ItemsContainer?: FC<{children: ReactNode}>
    ItemContainerElement?: FC<{children: ReactNode}> | string
    keyExtractor: (item: Result) => string
    useGetEndpoint: (
        params: Record<string, unknown>,
        options?: HookOptions | undefined,
    ) => Binding<
        Result[],
        void,
        Record<string, string | number | string[] | number[]>,
        any
    >
    usePostEndpoint?: (
        params: Record<string, unknown>,
        options?: HookOptions | undefined,
    ) => Binding<
        Result,
        void,
        Record<string, string | number | string[] | number[]>,
        any
    >
    params?: Record<string, any>
    pageParams?: PageParams
    resourceName?: string
    afterPostSuccess?: (item: Result) => void
}

const Resources = <Result, PageParams extends Record<string, any>>({
    RenderListItem,
    keyExtractor,
    useGetEndpoint,
    usePostEndpoint,
    params = {},
    resourceName = '',
    pageParams,
    RenderEmptyList,
    afterPostSuccess = () => null,
    ItemsContainer = ({children}) => <>{children}</>,
    ItemContainerElement = (
        props: React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >,
    ) => <div {...props} />,
}: Props<Result, PageParams>) => {
    const getResource = useGetEndpoint(params)
    const postResource = usePostEndpoint?.({})
    const [newItem, setNewItem] = useState<string>()
    const onSubmit: React.FormEventHandler<HTMLFormElement> = event => {
        if (newItem) {
            void postResource
                ?.perform(
                    {},
                    {
                        ...params,
                        Name: newItem,
                    },
                )
                .then(item => {
                    if (isSuccess(item)) {
                        setNewItem('')
                        getResource.invalidateCache()
                        afterPostSuccess(item.data)
                    }
                })
        }
        event.preventDefault()
    }
    const hasNoItems = getResource.data?.length === 0
    const EmptyListElement = () =>
        RenderEmptyList ? (
            <RenderEmptyList pageParams={pageParams ?? ({} as PageParams)} />
        ) : (
            <i>No {resourceName}s found</i>
        )

    return isSuccess(getResource) ? (
        <>
            <ItemsContainer>
                {getResource.data?.map(item => (
                    <ItemContainerElement key={keyExtractor(item)}>
                        <RenderListItem
                            item={item}
                            pageParams={pageParams ?? ({} as PageParams)}
                        />
                    </ItemContainerElement>
                ))}
                {hasNoItems && <EmptyListElement />}
            </ItemsContainer>
            <br />
            {!!postResource && (
                <form onSubmit={onSubmit}>
                    <label>
                        Add {resourceName}:
                        <br />
                        <input
                            type="text"
                            onChange={({target: {value}}) => setNewItem(value)}
                            value={newItem}
                        />
                        <input
                            type="submit"
                            value="Add"
                        />
                    </label>
                </form>
            )}
        </>
    ) : isFailed(getResource) ? (
        <>Error: {getResource.request.response?.status}</>
    ) : (
        <>Loading</>
    )
}

export default Resources
