import {FC} from 'react'
import {useParams} from 'react-router-dom'
import {useGetListsIdLists, usePutListsIdLists} from '../generated/apiData'
import ScreenWrapper from '../components/ScreenWrapper'
import {isSuccess} from '../utils/api'

type Params = {
    idLists: string
}

const ListSettingsScreen: FC = () => {
    const idLists = parseInt(useParams<Params>().idLists ?? '', 10)
    const getLists = useGetListsIdLists({
        idLists,
    })
    const putLists = usePutListsIdLists({
        idLists,
    })
    const ready =
        isSuccess(getLists) && putLists.request.networkStatus !== 'loading'
    return (
        <ScreenWrapper
            title={getLists.data?.Name ?? 'Project'}
            backURL={`/lists`}>
            <label>
                <input
                    type="checkbox"
                    disabled={!ready}
                    checked={isSuccess(getLists) && getLists.data.Resetable}
                    onChange={e => {
                        if (ready) {
                            void putLists.perform(undefined, {
                                ...getLists.data,
                                Resetable: e.target.checked,
                            })
                        }
                    }}
                />
                Allow action "Mark all items uncompleted"
            </label>
            <br />
            <label>
                <input
                    type="checkbox"
                    disabled={!ready}
                    checked={isSuccess(getLists) && getLists.data.Clearable}
                    onChange={e => {
                        if (ready) {
                            void putLists.perform(undefined, {
                                ...getLists.data,
                                Clearable: e.target.checked,
                            })
                        }
                    }}
                />
                Allow action "Delete all items"
            </label>
        </ScreenWrapper>
    )
}

export default ListSettingsScreen
