import {Routes, Route} from 'react-router'
import {BrowserRouter} from 'react-router-dom'
import {useSelector} from './hooks/useSelector'
import ListScreen from './screens/ListScreen'
import ListSettingsScreen from './screens/ListSettingsScreen'
import ListsScreen from './screens/ListsScreen'
import LoginScreen from './screens/LoginScreen'
import {isLoggedIn} from './state/user'

export const Router = () => {
    const loggedIn = useSelector(isLoggedIn)
    return (
        <BrowserRouter>
            <Routes>
                {loggedIn ? (
                    <>
                        <Route
                            path="/"
                            element={<ListsScreen />}
                        />
                        <Route
                            path="/lists"
                            element={<ListsScreen />}
                        />
                        <Route
                            path="/lists/:idLists"
                            element={<ListScreen />}
                        />
                        <Route
                            path="/lists/:idLists/settings"
                            element={<ListSettingsScreen />}
                        />
                    </>
                ) : (
                    <Route
                        path="*"
                        element={<LoginScreen />}
                    />
                )}
            </Routes>
        </BrowserRouter>
    )
}
